@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&family=Roboto&display=swap');

body {
  background-color: white;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  width: 100%;
}


h1, h2, h3 {
  font-family: 'Poppins', sans-serif;
}

h1 {
  font-size: 54px;
  font-weight: 600;
}

h2, h3 {
  font-weight: 500;
}

p {
  line-height: 1.6;
}