@import "./Styles/responsive.scss";

.App {

  .container {
    margin: 2em auto;
    width: 100%;
    max-width: 1440px;
    display: flex;

    .column {
      padding: 0 2em;
      &.column-right {
        margin-left: auto;
      }
    }
  }

  .btn {
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #000;
    padding: 1.3em 0.8em;
    transition: all 0.2s linear;

    &:hover {
      background-color: #2d2d2d;
      border-color: #ffffff;
      color: #fafafa;
    }

    .arrow {
      padding-left: 4em;
    }
  }

  .font-grey {
    opacity: 0.5;
  }

  .ml-1 {
    margin-left: 1em;
  }

  .ptb-6 {
    padding-top: 6em;
    padding-bottom: 6em;
  }


  .b-word {
    word-break: all;
  }

  .img-responsive {
    width: 100%;
    height: auto;
  }
}

