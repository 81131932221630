#QuickLinks {
    .link-category {
        flex-basis: 30%;
    }
    .linkList {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 0.5em;

            a {
                text-decoration: none;
                color: #000;
                transition: color 0.2s linear;

                &:hover {
                    color: #FF5621;
                    text-decoration: underline;
                }
            }
        }
    }
}