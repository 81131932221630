#Footer {
    .container {
        .column {
            flex-basis: 20%;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;

                li {
                    margin: 0 1em;
                }
            }

            &:nth-child(1) {
                flex-basis: 50%;
            }

            &:nth-child(2) {
                flex-basis: 30%;
            }

            &:last-child {
                ul {
                    justify-content: flex-end;
                }
            }
        }
    }
}